import { useEffect, useState } from "react";
import leftSide from "../assets/why_left.svg"
import rightSide from "../assets/right_image.svg"
const Main = () => {
    const [activeForm, setActiveForm] = useState('bakalavr');


    useEffect(() => {
        const wrapper=document.querySelector('.b24-form-border-left')
        wrapper?.classList?.add('border-none')

    }, []);

    return (
        <div className="relative mb-3.5">
            <main >
                <div className="">
                    <img src={leftSide}
                         className="absolute  -bottom-60  max-sm:hidden"/>
                </div>
                <div className="flex justify-center mt-4 gap-2 pb-5 max-sm:pb-3">
                    <button
                        className={`${activeForm === 'bakalavr' ? 'bg-[#3D9386] text-white' : ''} ml-2.5 border-2 border-[#3D9386] px-6 py-2`}
                        onClick={() => setActiveForm('bakalavr')}>
                        BAKALAVRIAT
                    </button>
                    <button
                        className={`${activeForm === 'magister' ? 'bg-[#3D9386] text-white' : ''} ml-2.5 border-2 border-[#3D9386] px-6 py-2`}
                        onClick={() => setActiveForm('magister')}>
                        MAGISTRATURA
                    </button>
                </div>
                <div className="form-container">
                    <div id="bakalavr-form" style={{display: activeForm === 'bakalavr' ? 'block' : 'none'}}>
                        <script data-b24-form="inline/12/xiawh8"
                                data-skip-moving="true">{(function (w, d, u) {
                            var s = d.createElement('script');
                            s.async = true;
                            s.src = u + '?' + (Date.now() / 180000 | 0);
                            var h = d.getElementsByTagName('script')[0];
                            h.parentNode.insertBefore(s, h);
                        })(window, document, 'https://bitrix.nordicun.uz/upload/crm/form/loader_12_xiawh8.js')
                        }</script>
                    </div>
                    <div id="magister-form" style={{display: activeForm === 'magister' ? 'block' : 'none'}}>
                        <script data-b24-form="inline/13/qku59r"
                                data-skip-moving="true">{(function (w, d, u) {
                            var s = d.createElement('script');
                            s.async = true;
                            s.src = u + '?' + (Date.now() / 180000 | 0);
                            var h = d.getElementsByTagName('script')[0];
                            h.parentNode.insertBefore(s, h);
                        })(window, document, 'https://bitrix.nordicun.uz/upload/crm/form/loader_13_qku59r.js')}
                        </script>
                    </div>
                </div>
            </main>
            <img src={rightSide} className="absolute max-sm:h-96 max-sm: right-0 top-[-200px] max-sm:hidden"/>
        </div>
    );
};

export default Main;
