import logo from "../assets/footer_logo.svg"

const Navbar = () => {
    return (
        <div className="flex justify-center items-center w-full bg-[#3D9386]">
            <div className="pt-5 pb-5">
                <img src={logo} />
            </div>
        </div>
    );
};

export default Navbar;