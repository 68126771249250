
import {FaPhoneAlt} from "react-icons/fa";
import {MdEmail} from "react-icons/md";
import {IoEarth} from "react-icons/io5";
import logo from "../assets/footer_logo.svg"

const Footer = () => {
    return (
        <>

            <div className="w-full bg-[#3D9386] bottom-0">
                <div className="px-8 pb-5">
                    <div
                        className="grid grid-cols-3 max-lg:grid-cols-1 py-5 max-lg:items-center items-center justify-between mt-6 max-sm:mt-0">
                        <div className="text-white mt-8 flex Neu-regular flex-col">
                            <span
                                className="text-left max-sm:text-center text-xl max-lg:text-center font-semibold pb-4">BIZ BILAN BOG’LANISH</span>
                            <div className="flex gap-2 pl-6 items-center max-lg:justify-center justify-start w-full">
                                <FaPhoneAlt className="text-lg"/>
                                <a className="text-left text-lg max-lg:text-center"
                                   href="tel:+998555084400"><span
                                    className="">+998555084400</span></a>
                            </div>
                            <div className="flex gap-2 items-center pl-6 max-lg:justify-center justify-start w-full">
                                <MdEmail className="text-lg"/>
                                <a className="text-left text-lg max-lg:text-center"
                                   href="mailto:info@nordicuniversity.org"><span
                                    className="">info@nordicuniversity.org</span></a>
                            </div>
                            <div className="flex gap-2 items-center pl-6 max-lg:justify-center justify-start w-full">
                                <IoEarth className="text-lg"/>
                                <a className="text-left max-lg:text-center text-lg"
                                   href="https://nordicuniversity.org/"><span
                                    className="">www.nordicuniversity.org</span></a>
                            </div>
                        </div>
                        <img src={logo} className="mb-9 max-lg:mt-8 h-12 m-auto block" alt="Logo"/>
                        <div className="text-white max-lg:pb-5">
                            <h3 className='max-lg:text-center text-right text-lg'>Xalqaro Nordik universiteti 2024.</h3>
                            <p className='max-lg:text-center text-right text-lg'>Barcha huquqlar himoyalangan.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};

export default Footer;