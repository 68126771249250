import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import Main from "./Components/Main";


function App() {
  return (
      <div className="flex flex-col min-h-screen">
        <Navbar/>
        <div className="flex-grow">
          <Main/>
        </div>
        <Footer/>
      </div>
  );
}

export default App;
